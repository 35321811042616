import i18n, { LANG_KAY } from '@utils/i18n';

export default () => {
    return [
        {
            key: 'categoryWorkbench',
            name: 'categoryWorkbench',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_WORKBENCH),
            children: [
                {
                    key: 'workingSpace',
                    name: 'workingSpace',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_WORKING_SPACE),
                    path: '/pipeline'
                },
                {
                    key: 'digitalIntelEmployees',
                    name: 'digitalIntelEmployees',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_DIGITAL_INTEL_EMPLOYEES),
                    path: '/pipeline'
                },
                {
                    key: 'unmannedPipeline',
                    name: 'unmannedPipeline',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_UNMANNED_PIPELINE),
                    path: '/pipeline'
                }
            ]
        },
        {
            key: 'categorySales',
            name: 'categorySales',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_SALES),
            children: [
                {
                    key: 'customerScreeningRobot',
                    name: 'customerScreeningRobot',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_CUSTOMER_SCREENING_ROBOT),
                    path: '/'
                },
                {
                    key: 'customerMgmt',
                    name: 'customerMgmt',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_CUSTOMER_MGMT),
                    path: '/market/#/account/list'
                },
                {
                    key: 'salesLeads',
                    name: 'salesLeads',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_SALES_LEADS),
                    path: '/'
                },
                {
                    key: 'intendedOrders',
                    name: 'intendedOrders',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_INTENDED_ORDERS),
                    path: '/products/#/oms/order'
                }
            ]
        },
        {
            key: 'categoryDigitalStore',
            name: 'categoryDigitalStore',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_DIGITAL_STORE),
            children: [
                {
                    key: 'myShop',
                    name: 'myShop',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_MY_SHOP),
                    path: '/'
                },
                {
                    key: 'storeAnalysis',
                    name: 'storeAnalysis',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_STORE_ANALYSIS),
                    path: '/'
                }
            ]
        },
        {
            key: 'categoryProduct',
            name: 'categoryProduct',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_PRODUCT),
            children: [
                {
                    key: 'productCategory',
                    name: 'productCategory',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_PRODUCT_CATEGORY),
                    path: '/products/#/pms/productCate'
                },
                {
                    key: 'productList',
                    name: 'productList',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_PRODUCT_LIST),
                    path: '/products/#/pms/product'
                },
                {
                    key: 'productAnalysis',
                    name: 'productAnalysis',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_PRODUCT_ANALYSIS),
                    path: '/products/#/pms/productAttr'
                }
            ]
        },
        {
            key: 'categoryIntelligentTools',
            name: 'categoryIntelligentTools',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_INTELLIGENT_TOOLS),
            children: [
                {
                    key: 'search',
                    name: 'search',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_AI_SEARCH),
                    path: '/aitools#/search'
                },
                {
                    key: 'chatgpt',
                    name: 'ChatGPT',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_CHATGPT),
                    path: '/aitools#/toolbox'
                },
                {
                    key: 'graphicRobot',
                    name: 'graphicRobot',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_GRAPHIC_ROBOT),
                    path: '/aitools#/draw'
                },
                {
                    key: 'mailAssistant',
                    name: 'mailAssistant',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_MAIL_ASSISTANT),
                    path: '/aitools'
                },
                {
                    key: 'whatsapp',
                    name: 'whatsApp',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_WHATSAPP),
                    path: '/aitools'
                }
            ]
        },
        {
            key: 'categoryCrossBorderWarehouse',
            name: 'categoryCrossBorderWarehouse',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_CROSS_BORDER_WAREHOUSE),
            children: [
                {
                    key: 'itemproductAtlas',
                    name: 'itemproductAtlas',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEMPRODUCT_ATLAS),
                    path: '/'
                },
                {
                    key: 'stakeholderMap',
                    name: 'stakeholderMap',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_STAKEHOLDER_MAP),
                    path: '/'
                },
                {
                    key: 'friendlyBusinessAtlas',
                    name: 'friendlyBusinessAtlas',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_FRIENDLY_BUSINESS_ATLAS),
                    path: '/'
                }
            ]
        },
        {
            key: 'categoryMarket',
            name: 'categoryMarket',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_MARKET),
            children: [
                {
                    key: 'marketAnalysisRobot',
                    name: 'marketAnalysisRobot',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_MARKET_ANALYSIS_ROBOT),
                    path: '/'
                },
                {
                    key: 'industryAnalysis',
                    name: 'industryAnalysis',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_INDUSTRY_ANALYSIS),
                    path: '/'
                },
                {
                    key: 'selectionAnalysis',
                    name: 'selectionAnalysis',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_SELECTION_ANALYSIS),
                    path: '/'
                },
                {
                    key: 'marketDocuments',
                    name: 'marketDocuments',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_MARKET_DOCUMENTS),
                    path: '/'
                },
                {
                    key: 'marketPlanning',
                    name: 'marketPlanning',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_MARKET_PLANNING),
                    path: '/products/#/sms/subject'
                },
                {
                    key: 'listingPromotion',
                    name: 'listingPromotion',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_LISTING_PROMOTION),
                    path: '/products/#/sms/advertise'
                }
            ]
        },
        {
            key: 'categoryCustomerService',
            name: 'categoryCustomerService',
            title: i18n.get(LANG_KAY.MENU_TITLE_CATEGORY_CUSTOMER_SERVICE),
            children: [
                {
                    key: 'customerServiceRobot',
                    name: 'customerServiceRobot',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_CUSTOMER_SERVICE_ROBOT),
                    path: '/'
                },
                {
                    key: 'vipService',
                    name: 'vipService',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_VIP_SERVICE),
                    path: '/'
                },
                {
                    key: 'userVoice',
                    name: 'userVoice',
                    title: i18n.get(LANG_KAY.MENU_TITLE_ITEM_USER_VOICE),
                    path: '/'
                }
            ]
        },
        {
            key: 'yunjingtenantservice',
            name: 'yunjingtenantservice',
            title: i18n.get('MENU_TITLE_ITEM_YUNJING_MGMR'),
            children: [
                {
                    key: 'ioTCard',
                    name: 'ioTCard',
                    title: i18n.get('MENU_TITLE_ITEM_YUNJING_MGMR_IOT'),
                    path: '/yunjing/#/ioTCard'
                },
                {
                    key: 'trafficCard',
                    name: 'trafficCard',
                    title: i18n.get('MENU_TITLE_ITEM_YUNJING_MGMR_TRAFFIC'),
                    path: '/yunjing/#/trafficCard'
                }
            ]
        }
    ];
};
