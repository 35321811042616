import header from './header';
import menu from './menu';

export default {
    COMMON_TITLE_LOADING: '加载中...',
    COMMON_TITLE_STAY_TUNED: '敬请期待',
    COMMON_TITLE_STAY_TUNED_TIPS: '我们正在加油赶来的路上！敬请期待...',
    ...header,
    ...menu
};
