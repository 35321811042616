export default {
    MENU_TITLE_HOME: '首页',
    MENU_TITLE_SYSTEM_NAME1: '生意通',
    MENU_TITLE_SYSTEM_NAME2: '鲸客',
    MENU_TITLE_SYSTEM_NAME3: '拓云科技',
    MENU_TITLE_CATEGORY_WORKBENCH: '工作台',
    MENU_TITLE_CATEGORY_MARKET: '市场',
    MENU_TITLE_CATEGORY_SALES: '销售',
    MENU_TITLE_CATEGORY_CUSTOMER_SERVICE: '客服',
    MENU_TITLE_CATEGORY_DIGITAL_STORE: '数字商店',
    MENU_TITLE_CATEGORY_INTELLIGENT_TOOLS: '智能工具',
    MENU_TITLE_CATEGORY_CROSS_BORDER_WAREHOUSE: '跨境数仓',
    MENU_TITLE_CATEGORY_PRODUCT: '商品',

    MENU_TITLE_ITEM_WORKING_SPACE: '工作空间',
    MENU_TITLE_ITEM_DIGITAL_INTEL_EMPLOYEES: '数智员工',
    MENU_TITLE_ITEM_UNMANNED_PIPELINE: '无人流水线',
    MENU_TITLE_ITEM_MARKET_ANALYSIS_ROBOT: '市场分析机器人',
    MENU_TITLE_ITEM_INDUSTRY_ANALYSIS: '行业分析',
    MENU_TITLE_ITEM_SELECTION_ANALYSIS: '选品分析',
    MENU_TITLE_ITEM_MARKET_DOCUMENTS: '营销文案',
    MENU_TITLE_ITEM_MARKET_PLANNING: '营销规划',
    MENU_TITLE_ITEM_LISTING_PROMOTION: '上架推广',
    MENU_TITLE_ITEM_CUSTOMER_SCREENING_ROBOT: '客户筛选机器人',
    MENU_TITLE_ITEM_CUSTOMER_MGMT: '客户管理',
    MENU_TITLE_ITEM_SALES_LEADS: '销售线索',
    MENU_TITLE_ITEM_INTENDED_ORDERS: '意向订单',
    MENU_TITLE_ITEM_CUSTOMER_SERVICE_ROBOT: '客服机器人',
    MENU_TITLE_ITEM_VIP_SERVICE: 'VIP服务',
    MENU_TITLE_ITEM_USER_VOICE: '用户声音',
    MENU_TITLE_ITEM_MY_SHOP: '我的店铺',
    MENU_TITLE_ITEM_STORE_ANALYSIS: '店铺分析',
    MENU_TITLE_ITEM_AI_SEARCH: 'AI搜索',
    MENU_TITLE_ITEM_CHATGPT: 'ChatGPT',
    MENU_TITLE_ITEM_GRAPHIC_ROBOT: '图文机器人',
    MENU_TITLE_ITEM_MAIL_ASSISTANT: '邮件助手',
    MENU_TITLE_ITEM_WHATSAPP: 'whatsapp',
    MENU_TITLE_ITEMPRODUCT_ATLAS: '商品图谱',
    MENU_TITLE_ITEM_STAKEHOLDER_MAP: '干系人图谱',
    MENU_TITLE_ITEM_FRIENDLY_BUSINESS_ATLAS: '友商图谱',
    MENU_TITLE_ITEM_PRODUCT_CATEGORY: '商品分类',
    MENU_TITLE_ITEM_PRODUCT_LIST: '商品列表',
    MENU_TITLE_ITEM_PRODUCT_ANALYSIS: '商品分析',
    MENU_TITLE_ITEM_PRODUCT_WIN_THE_CUSTOMER: '客户管理',
    MENU_TITLE_ITEM_YUNJING_MGMR: '云鲸管理台',
    MENU_TITLE_ITEM_YUNJING_MGMR_IOT: '物联网卡',
    MENU_TITLE_ITEM_YUNJING_MGMR_TRAFFIC: '流量卡',
    MENU_TITLE_ITEM_YUNJING_MGMR_RETAIL: '租户分佣'
};
