export default {
    TITLE_HEADER_DOCUMENT_CENTER: 'Help',
    TITLE_HEADER_INRELIIGENT_CUSTOMER_SERVICE: 'Customer Service',
    TITLE_HEADER_SHARE: 'Share',
    TITLE_HEADER_PERSONAL_CONTER: 'Personal Center',
    TITLE_HEADER_COST_CONTER: 'Recharge Management',
    TITLE_HEADER_RESOURCE_CONTER: 'Resource Center',
    TITLE_HEADER_OPERATION_CONTER: 'Operation Center',
    TITLE_HEADER_IOT_CARD: 'IoT Card',
    TITLE_HEADER_TRAFFIC_CARD: 'Traffic Card',
    TITLE_HEADER_DEV_CUSTOMER_CENTER: 'Develop Customer Mgmt',
    TITLE_HEADER_LOG_OUT: 'Logout',
    TITLE_HEADER_CONTACT_CUSTOMER_SERVICE: 'CSC',
    TITLE_HEADER_SCAN_CONTACT_CUSTOMER_SERVICE: 'contact customer service',
    TITLE_HEADER_FEEDBACK: 'Feedback',
    TITLE_HEADER_QUESTION_CATEGORY: 'Question Category',
    TITLE_HEADER_QUESTION_TITLE: 'Question Title',
    TITLE_HEADER_QUESTION_DESCRIPTION: 'Question Description',
    TITLE_HEADER_PLACEHOLD_QUESTION_CATEGORY: 'Please select a category',
    TITLE_HEADER_QUESTION_CATEGORY_DEBUG: 'Debug',
    TITLE_HEADER_QUESTION_CATEGORY_SUGESTIONS: 'Sugestion',
    TITLE_HEADER_QUESTION_CATEGORY_OTHER: 'Other',

    TIPS_HEADER_SHARE_COPY_SUCCESS: 'Successfully copied the sharing link.'
};
