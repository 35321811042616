import React from 'react';
import { useLocation } from 'react-router-dom';
import apps from '@/appConfig/apps';

import './index.less';

export default () => {
    const { pathname } = useLocation();
    const isShow = !isHidden();

    function isHidden() {
        return apps.some((item) => pathname.startsWith(item.props.activePath));
    }

    return (
        isShow && (
            <div className="not-found-container">
                <img src="/images/notFound.svg"></img>
                <div>找不到资源</div>
            </div>
        )
    );
};
