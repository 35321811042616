import { useState, useEffect } from 'react';

function useMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|SymbianOS)/i
        );
        setIsMobile(Boolean(flag));
    }, []);

    return isMobile;
}

export default useMobile;
