import request from './request';
import path from './path';

export function createFeedback(data) {
    return request({
        url: path.feedback,
        method: 'post',
        data: data
    });
}
