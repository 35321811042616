import header from './header';
import menu from './menu';

export default {
    COMMON_TITLE_LOADING: 'Loading...',
    COMMON_TITLE_STAY_TUNED: 'Stay tuned',
    COMMON_TITLE_STAY_TUNED_TIPS: 'We are on our way! Please stay tuned...',
    ...header,
    ...menu
};
