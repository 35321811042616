import { BASE_NAME } from '@utils/constants';

const microApps = [
    {
        name: 'consolepipeline',
        entry: window.location.origin + '/pipeline/',
        activeRule: '/pipeline',
        container: '#subapp-viewport'
    },
    {
        name: 'consoleiam',
        entry: window.location.origin + '/iam/',
        activeRule: '/iam',
        container: '#subapp-viewport'
    },
    {
        name: 'consoleproducts',
        entry: window.location.origin + '/products/',
        activeRule: '/products',
        container: '#subapp-viewport'
    },
    {
        name: 'consoleaitools',
        entry: window.location.origin + '/aitools/',
        activeRule: '/aitools',
        container: '#subapp-viewport'
    },
    {
        name: 'consolemarket',
        entry: window.location.origin + '/market/',
        activeRule: '/market',
        container: '#subapp-viewport'
    },
    {
        name: 'consoleyunjing',
        entry: window.location.origin + '/yunjing/',
        activeRule: '/yunjing',
        container: '#subapp-viewport'
    }
];

const apps = microApps.map((item) => {
    return {
        ...item,
        activeRule: BASE_NAME + item.activeRule,
        props: {
            routerBase: item.activeRule,
            activePath: item.activeRule
        }
    };
});

export default apps;
