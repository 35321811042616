/* eslint-disable no-unused-vars */

function handleFetch(headerCfg) {
    // 保存原始的 fetch 方法
    const originalFetch = window.fetch;

    // 重写 fetch 方法
    window.fetch = function (url, options) {
        // 在请求发送前对请求进行处理
        // 设置请求头参数
        if (!options.headers) {
            options.headers = {};
        }

        options.headers = { ...options.headers, ...headerCfg };

        // 调用原始的 fetch 方法发送请求
        return originalFetch(url, options);
    };
}

function handleXHR(headerCfg) {
    // 保存原始的 open 方法
    const originalOpen = XMLHttpRequest.prototype.open;

    // 重写 open 方法
    XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
        // 调用原始的 open 方法
        originalOpen.apply(this, arguments);

        // 在这里可以进行拦截和修改请求头参数的操作
        Object.entries(headerCfg).forEach(([key, value]) => {
            this.setRequestHeader(key, value);
        });
    };
}

export { handleFetch, handleXHR };
