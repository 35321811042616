import React from 'react';
import { useRoutes } from 'react-router-dom';
import NotFound from './NotFound';

export default (props) => {
    const { routes } = props;

    const routers = useRoutes([
        ...routes,
        {
            name: 'error',
            path: '*',
            element: <NotFound />
        }
    ]);

    return routers;
};
