import React, { useState, useEffect, useContext } from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import commonContext from '../../utils/commonContext';

import config from './config';
import { ReactComponent as MenuShrink } from '@/assets/images/menuShrink.svg';
import { ReactComponent as MenuExpand } from '@/assets/images/menuExpand.svg';

import './index.less';

export default (props) => {
    const consumer = useContext(commonContext);
    const menu = config();
    const { lang } = props;
    const [isShow, setIsShow] = useState(false);
    const [menuList, setMenuList] = useState(menu);

    function onClickMenuIcon() {
        setIsShow(!isShow);
    }

    function onClose() {
        setIsShow(false);
    }

    function resetMenu() {
        const userMenuName = consumer.map((item) => {
            if (item.name.includes('.')) {
                return item.name.split('.')[1];
            }

            return item.name;
        });

        const menuArr = menu.filter((item) => {
            if (item.children) {
                const arr = item.children.filter((child) => {
                    return userMenuName.includes(child.name);
                });

                if (arr.length === 0) {
                    return false;
                } else {
                    item.children = arr;
                }
            }

            return true;
        });

        return menuArr;
    }

    useEffect(() => {
        setMenuList(resetMenu());
    }, [lang, consumer]);

    return (
        <div className="menu-container">
            <div className="op-icon" onClick={onClickMenuIcon}>
                {isShow ? <MenuExpand /> : <MenuShrink />}
            </div>

            <Drawer placement="left" width="32rem" onClose={onClose} open={isShow} rootClassName="menu-drawer">
                <div className="menu-content">
                    {menuList.map((item) => {
                        const { key, title, children = [] } = item;
                        return (
                            <div className="menu-item-wrap" key={key}>
                                <div className="menu-item-title">{title}</div>

                                {children.map((child) => {
                                    return (
                                        <div className="menu-item" key={child.key} onClick={onClose}>
                                            <Link to={child.path}>{child.title}</Link>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Drawer>
        </div>
    );
};
