import intl from 'react-intl-universal';
import zhCN from '@/locales/zh-cn';

export default {
    get(key, params) {
        return intl.get(key, params);
    }
};

const LANG_KAY = new Proxy(zhCN, {
    get(target, key) {
        return key;
    }
});

export { LANG_KAY };
