import request from './request';
import path from './path';

export function getUserInfo(params, config) {
    const { username } = params;

    return request({
        url: path.userInfo,
        method: 'get',
        params: {
            username: username
        },
        ...config
    });
}

export function logout() {
    return request({
        url: path.logout,
        method: 'post'
    });
}

export function getUserMenu() {
    return request({
        url: path.userMenu,
        method: 'get'
    });
}
