/* eslint-disable no-console */
import axios from 'axios';
import { notification, Modal } from 'antd';
import { gotoLogin } from '@utils/util';

function showMessage(res, response) {
    // 401:未登录;
    if (res.code === 401 || res.status === 401) {
        if (!response?.config?.headers?.notShowError) {
            Modal.confirm({
                title: '请重新登录',
                content: '您的会话已过期，请重新登录！',
                okText: '重新登录',
                cancelText: '取消',
                onOk: () => {
                    gotoLogin();
                }
            });
        }
    } else {
        notification.error({
            message: res?.data?.message || res?.message || response?.message || 'error'
        });
    }
}

// 创建axios实例
const service = axios.create({
    baseURL: process.env.BASE_API, // api的base_url
    timeout: 120000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    (response) => {
        /**
         * code为非200是抛错 可结合自己业务进行修改
         */
        const res = response.data;

        if (res.code !== 200) {
            showMessage(res, response);

            return Promise.reject(res?.message || 'error');
        } else {
            return response.data;
        }
    },
    (error) => {
        console.log('err' + error); // for debug
        showMessage(error.response, error);

        return Promise.reject(error);
    }
);

export default service;
