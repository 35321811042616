/* eslint-disable no-console */
import React, { useState } from 'react';
import { FloatButton, Modal, Form, Input, Select, message, Popover } from 'antd';
import { CommentOutlined, CustomerServiceOutlined, QuestionOutlined } from '@ant-design/icons';
import { createFeedback } from '@/api/feedback';
import { onClickDoc } from '@utils/util';
import i18n, { LANG_KAY } from '@utils/i18n';
import store from '@/store';

import './index.less';

const Option = Select.Option;
const { TextArea } = Input;

function CustomerService() {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const state = store.getGlobalState();
    const feebackCategory = [
        {
            label: i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_CATEGORY_DEBUG),
            value: 'DEBUG'
        },
        {
            label: i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_CATEGORY_SUGESTIONS),
            value: 'SUGESTIONS'
        },
        {
            label: i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_CATEGORY_OTHER),
            value: 'OTHER'
        }
    ];

    function cancelModal() {
        setVisible(false);
        form.resetFields();
    }

    function submit() {
        form.validateFields().then(async (values) => {
            setLoading(true);

            const resp = await createFeedback(values);

            setLoading(false);

            if (resp) {
                cancelModal();
                messageApi.open({
                    type: 'success',
                    content: '您反馈的问题我们已经收到，请耐心等待后续通知！'
                });
            }
        });
    }

    function onClickManual() {
        // 展示客户微信二维码
    }

    function onClickWorkOrder() {
        // 跳转到问题反馈界面
        setVisible(true);
    }

    function onOpenChange(value) {
        setOpen(value);
    }

    return (
        <div>
            <FloatButton.Group
                trigger="hover"
                type="primary"
                description={i18n.get(LANG_KAY.TITLE_HEADER_CONTACT_CUSTOMER_SERVICE)}
                className={`global-customer-service ${open ? 'open' : ''}`}
                icon={!state.isMobile && <CustomerServiceOutlined />}
                open={open}
                onOpenChange={onOpenChange}
            >
                <FloatButton icon={<QuestionOutlined />} onClick={onClickDoc} />
                <FloatButton onClick={onClickWorkOrder} />
                <Popover
                    placement="left"
                    content={
                        <div className="quota-content">
                            <div className="image">
                                <img src="/images/customerServiceQr.png"></img>
                            </div>
                            <div className="description">
                                {i18n.get(LANG_KAY.TITLE_HEADER_SCAN_CONTACT_CUSTOMER_SERVICE)}
                            </div>
                        </div>
                    }
                    trigger="click"
                >
                    <FloatButton icon={<CommentOutlined />} onClick={onClickManual} />
                </Popover>
            </FloatButton.Group>

            <Modal
                title={i18n.get(LANG_KAY.TITLE_HEADER_FEEDBACK)}
                width={660}
                open={visible}
                confirmLoading={loading}
                onOk={submit}
                onCancel={cancelModal}
            >
                <Form form={form} initialValues={{}} labelCol={{ span: 6 }}>
                    <Form.Item
                        name="category"
                        label={i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_CATEGORY)}
                        rules={[{ required: true }]}
                    >
                        <Select placeholder={i18n.get(LANG_KAY.TITLE_HEADER_PLACEHOLD_QUESTION_CATEGORY)}>
                            {feebackCategory.map((item) => {
                                const { label, value } = item;
                                return (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label={i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_TITLE)}
                        rules={[{ required: true }]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={i18n.get(LANG_KAY.TITLE_HEADER_QUESTION_DESCRIPTION)}
                        rules={[{ required: true }]}
                    >
                        <TextArea rows={4} autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </div>
    );
}

export default CustomerService;
