function setSize() {
    let baseFontSize = 14;
    let baseWidth = 750;
    let clientWidth = document.documentElement.clientWidth;

    if (clientWidth > 750) {
        clientWidth = 750;
    }

    let fontSize = Math.max(Math.ceil(baseFontSize * (clientWidth / baseWidth)), 10);
    fontSize = Math.min(fontSize, 14);

    document.documentElement.style.fontSize = fontSize + 'px';
}

setSize();

window.addEventListener('resize', setSize);
