export default {
    MENU_TITLE_HOME: 'Home',
    MENU_TITLE_SYSTEM_NAME1: 'Tik TuoYun',
    MENU_TITLE_SYSTEM_NAME2: 'TikTalents',
    MENU_TITLE_SYSTEM_NAME3: 'TuoYun Tech',
    MENU_TITLE_CATEGORY_WORKBENCH: 'Workbench',
    MENU_TITLE_CATEGORY_MARKET: 'Market',
    MENU_TITLE_CATEGORY_SALES: 'Sales',
    MENU_TITLE_CATEGORY_CUSTOMER_SERVICE: 'Customer Service',
    MENU_TITLE_CATEGORY_DIGITAL_STORE: 'Digital Store',
    MENU_TITLE_CATEGORY_INTELLIGENT_TOOLS: 'Intelligent Tools',
    MENU_TITLE_CATEGORY_CROSS_BORDER_WAREHOUSE: 'Cross Border Data Warehouse',
    MENU_TITLE_CATEGORY_PRODUCT: 'Product',

    MENU_TITLE_ITEM_WORKING_SPACE: 'Working Space',
    MENU_TITLE_ITEM_DIGITAL_INTEL_EMPLOYEES: 'Digital Intelligence Employees',
    MENU_TITLE_ITEM_UNMANNED_PIPELINE: 'Unmanned Pipeline',
    MENU_TITLE_ITEM_MARKET_ANALYSIS_ROBOT: 'Market Analysis Robot',
    MENU_TITLE_ITEM_INDUSTRY_ANALYSIS: 'Industry Analysis',
    MENU_TITLE_ITEM_SELECTION_ANALYSIS: 'Selection Analysis',
    MENU_TITLE_ITEM_MARKET_DOCUMENTS: 'Marketing Documents',
    MENU_TITLE_ITEM_MARKET_PLANNING: 'Marketing Planning',
    MENU_TITLE_ITEM_LISTING_PROMOTION: 'Listing Promotion',
    MENU_TITLE_ITEM_CUSTOMER_SCREENING_ROBOT: 'Customer Screening Robot',
    MENU_TITLE_ITEM_CUSTOMER_MGMT: 'Customer Management',
    MENU_TITLE_ITEM_SALES_LEADS: 'Sales Leads',
    MENU_TITLE_ITEM_INTENDED_ORDERS: 'Intended Orders',
    MENU_TITLE_ITEM_CUSTOMER_SERVICE_ROBOT: 'Customer Service Robot',
    MENU_TITLE_ITEM_VIP_SERVICE: 'VIP Service',
    MENU_TITLE_ITEM_USER_VOICE: 'User Voice',
    MENU_TITLE_ITEM_MY_SHOP: 'My Shop',
    MENU_TITLE_ITEM_STORE_ANALYSIS: 'Store Analysis',
    MENU_TITLE_ITEM_AI_SEARCH: 'AI Search',
    MENU_TITLE_ITEM_CHATGPT: 'ChatGPT',
    MENU_TITLE_ITEM_GRAPHIC_ROBOT: 'Graphic Robot',
    MENU_TITLE_ITEM_MAIL_ASSISTANT: 'Mail Assistant',
    MENU_TITLE_ITEM_WHATSAPP: 'whatsapp',
    MENU_TITLE_ITEMPRODUCT_ATLAS: 'Product Atlas',
    MENU_TITLE_ITEM_STAKEHOLDER_MAP: 'Stakeholder Atlas',
    MENU_TITLE_ITEM_FRIENDLY_BUSINESS_ATLAS: 'Friendly Business Atlas',
    MENU_TITLE_ITEM_PRODUCT_CATEGORY: 'Product Category',
    MENU_TITLE_ITEM_PRODUCT_LIST: 'Product List',
    MENU_TITLE_ITEM_PRODUCT_ANALYSIS: 'Product Analysis',
    MENU_TITLE_ITEM_PRODUCT_WIN_THE_CUSTOMER: 'Win the Customer',
    MENU_TITLE_ITEM_YUNJING_MGMR: 'Cloud Whale Management',
    MENU_TITLE_ITEM_YUNJING_MGMR_IOT: 'IOT Card',
    MENU_TITLE_ITEM_YUNJING_MGMR_TRAFFIC: 'Traffic Card',
    MENU_TITLE_ITEM_YUNJING_MGMR_RETAIL: 'Tenant Retail'
};
