import React from 'react';
import i18n, { LANG_KAY } from '@utils/i18n';
import { Link } from 'react-router-dom';

function headerConfig() {
    return [
        {
            key: 'user',
            label: <Link to="/iam/#/user/info">{i18n.get(LANG_KAY.TITLE_HEADER_PERSONAL_CONTER)}</Link>
        },
        {
            key: 'costCenter',
            label: <Link to="/iam/#/cost/center">{i18n.get(LANG_KAY.TITLE_HEADER_COST_CONTER)}</Link>
        },
        // {
        //     key: 'personalRes',
        //     label: <Link to="/iam/#/personalRes/center">{i18n.get(LANG_KAY.TITLE_HEADER_RESOURCE_CONTER)}</Link>
        // },
        {
            key: 'joinRetail',
            label: <Link to="/iam/#/joinRetail/center">{i18n.get(LANG_KAY.TITLE_HEADER_JOINRETAIL_CONTER)}</Link>
        },
        {
            key: 'ums',
            label: <Link to="/iam/#/ums/admin">{i18n.get(LANG_KAY.TITLE_HEADER_OPERATION_CONTER)}</Link>
        },
        {
            key: 'yunjingAdminIoTCard',
            label: <Link to="/iam/#/adminIoTCard/overview">{i18n.get(LANG_KAY.TITLE_HEADER_IOT_CARD)}</Link>
        },
        {
            key: 'yunjingAdminTrafficCard',
            label: <Link to="/iam/#/adminTrafficCard/overview">{i18n.get(LANG_KAY.TITLE_HEADER_TRAFFIC_CARD)}</Link>
        },
        {
            key: 'developCustomer',
            label: (
                <Link to="/iam/#/developCustomer/adminDCIpCenter">
                    {i18n.get(LANG_KAY.TITLE_HEADER_DEV_CUSTOMER_CENTER)}
                </Link>
            )
        }
    ];
}

export { headerConfig };
