export default {
    TITLE_HEADER_DOCUMENT_CENTER: '帮助',
    TITLE_HEADER_INRELIIGENT_CUSTOMER_SERVICE: '智能客服',
    TITLE_HEADER_SHARE: '分享',
    TITLE_HEADER_PERSONAL_CONTER: '个人中心',
    TITLE_HEADER_COST_CONTER: '充值管理',
    TITLE_HEADER_RESOURCE_CONTER: '资源中心',
    TITLE_HEADER_OPERATION_CONTER: '运维中心',
    TITLE_HEADER_JOINRETAIL_CONTER: '加盟分销',
    TITLE_HEADER_IOT_CARD: '物联网卡',
    TITLE_HEADER_TRAFFIC_CARD: '流量卡',
    TITLE_HEADER_DEV_CUSTOMER_CENTER: '拓客管理',
    TITLE_HEADER_LOG_OUT: '退出登录',
    TITLE_HEADER_CONTACT_CUSTOMER_SERVICE: '联系客服',
    TITLE_HEADER_SCAN_CONTACT_CUSTOMER_SERVICE: '扫码联系客服',
    TITLE_HEADER_FEEDBACK: '反馈问题',
    TITLE_HEADER_QUESTION_CATEGORY: '问题类别',
    TITLE_HEADER_QUESTION_TITLE: '问题标题',
    TITLE_HEADER_QUESTION_DESCRIPTION: '问题描述',
    TITLE_HEADER_PLACEHOLD_QUESTION_CATEGORY: '请选择类别',
    TITLE_HEADER_QUESTION_CATEGORY_DEBUG: '问题反馈',
    TITLE_HEADER_QUESTION_CATEGORY_SUGESTIONS: '优化建议',
    TITLE_HEADER_QUESTION_CATEGORY_OTHER: '其他问题',

    TIPS_HEADER_SHARE_COPY_SUCCESS: '复制分享链接成功。'
};
