export default {
    logout: '/tytech-account/account/logout',
    userInfo: '/tytech-account/account/v2/loadByUsername',
    userMenu: '/tytech-account/account/info',

    // 余额
    quotaMargin: '/tytech-cost-service/product/quota/margin',

    // 问题反馈
    feedback: '/tytech-cost-service/dev_ops/feedback'
};

export function getRealPath(url, params) {
    let path = url;

    Object.entries(params).forEach(([key, value]) => {
        const reg = new RegExp(`{${key}}`, 'g');
        path = path.replace(reg, value);
    });

    return path;
}
