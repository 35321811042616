/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { registerMicroApps, start } from 'qiankun';

import App from '@components/App';
import apps from '@/appConfig/apps';
import store from '@/store';
import { getUserInfo, getUserMenu } from '@/api/common';
import { setUserName, getToken } from '@utils/auth';
import { gotoLogin } from '@utils/util';
import { handleFetch, handleXHR } from '@/api/customFetch';
import { Provider } from './utils/commonContext';
import '@utils/setFontSize';

import './index.less';
import './style/global.less';

let root = null;

async function render({ isSubApp, loading, token }) {
    if (!root) {
        root = ReactDOM.createRoot(document.getElementById('main-root'));
    }

    let menu = [];
    if (token || isSubApp) {
        const res = await getUserMenu();

        if (res && res.code === 200) {
            menu = res?.data?.menus;
        }

        store.setGlobalState({
            menus: menu
        });
    }

    root.render(
        <Provider value={menu}>
            <React.StrictMode>
                <App loading={loading} isSubApp={isSubApp} />
            </React.StrictMode>
        </Provider>
    );
}

const loader = (loading) => render({ loading, isSubApp: true });

async function startMainApp() {
    try {
        render({ loading: true });

        const tokenStr = getToken();

        // 通过token解析用户名
        const strings = tokenStr.split('.');
        let userInfo = JSON.parse(
            decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))))
        );
        setUserName(userInfo.user_name);

        const infoResp = await getUserInfo({ username: userInfo.user_name }, { headers: { Authorization: tokenStr } });

        store.setGlobalState({
            user: infoResp?.data || {},
            isLogin: true,
            token: tokenStr
        });

        renderMainApp({ token: tokenStr });
    } catch (e) {
        console.log('e=', e);
        gotoLogin();
    }
}

function renderMainApp({ token }) {
    render({ loading: false, token });

    const microApps = apps.map((app) => ({
        ...app,
        loader
    }));

    // 主应用统一拦截请求，给header设置token
    const header = { Authorization: token };
    handleFetch(header);
    handleXHR(header);

    registerMicroApps(microApps, {
        beforeLoad: (app) => {
            console.log('before load app.name=====>>>>>', app.name);
        },
        beforeMount: [
            (app) => {
                console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
            }
        ],
        afterMount: [
            (app) => {
                console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
            }
        ],
        afterUnmount: [
            (app) => {
                console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
            }
        ]
    });

    start();
}

startMainApp();
