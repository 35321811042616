/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, message, Spin } from 'antd';
import intl from 'react-intl-universal';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import moment from 'moment';
import { StyleProvider, px2remTransformer } from '@ant-design/cssinjs';

import { LANG, BASE_NAME } from '@utils/constants';
import Routers from '@components/Routers';
import Header from '@components/Header';
import CustomerService from '@components/CustomerService';
import store from '@/store';
import locales from '@/locales';
import useMobile from '@/hooks/useMobile';
import routeCfg from './routeCfg';

import './index.less';

const MOMENT_LANGS = {
    'zh-CN': 'zh-CN',
    'en-US': 'en'
};

const ANTD_LANGS = {
    'zh-CN': zhCN,
    'en-US': enUS
};

message.config({
    top: 100
});

function App(props) {
    const { loading, isSubApp = false } = props;
    const state = store.getGlobalState();
    const [lang, setLang] = useState(state.lang);
    const [initCompleted, setInitCompleted] = useState(null);
    const [isLoding, setIsLoding] = useState(loading);
    const isMobile = useMobile();

    function initLang() {
        let language = lang;

        if (![LANG.zhCN.key, LANG.enUS.key].includes(lang)) {
            language = LANG.zhCN.key;
        }

        loadLocales(language);
    }

    function handleRoute() {
        const pathname = window.location.pathname;

        if (pathname === '/') {
            history.replaceState({}, '', BASE_NAME);
        }
    }

    function loadLocales(language, callbackFn) {
        moment.locale(MOMENT_LANGS[lang]);
        intl.init({
            currentLocale: language,
            locales
        }).then(() => {
            setInitCompleted(new Date());

            if (callbackFn) {
                callbackFn();
            }
        });
    }

    function changeLang(val) {
        loadLocales(val, () => {
            setLang(val);
            localStorage.setItem('lang', val);
        });
    }

    function handleLoading() {
        setTimeout(() => {
            setIsLoding(false);
        }, 3000);
    }

    const px2rem = px2remTransformer({
        rootValue: 14 // 14px = 1rem; @default 16
    });

    useEffect(() => {
        setIsLoding(loading);
    }, [loading]);

    useEffect(() => {
        store.setGlobalState({ isMobile });
    }, [isMobile]);

    useEffect(() => {
        handleLoading();
        handleRoute();
        initLang();
    }, []);

    return (
        <ConfigProvider
            locale={ANTD_LANGS[lang]}
            theme={{
                token: { colorPrimary: '#18b3b3' }
            }}
        >
            {initCompleted && (
                <BrowserRouter basename={BASE_NAME}>
                    <StyleProvider transformers={[px2rem]}>
                        <Spin tip="Loading" size="large" spinning={false && isLoding}>
                            <div className={`mainapp ${isMobile ? 'mobile' : ''}`}>
                                <Header changeLang={changeLang} defaultLang={lang} />

                                <Spin tip="Loading" size="large" spinning={isSubApp && isLoding}>
                                    <div className="mainapp-main">
                                        <Routers routes={routeCfg} />

                                        {/* 子应用 */}
                                        <main id="subapp-viewport"></main>
                                    </div>
                                </Spin>

                                <CustomerService />
                            </div>
                        </Spin>
                    </StyleProvider>
                </BrowserRouter>
            )}
        </ConfigProvider>
    );
}

export default App;
