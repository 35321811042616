import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import i18n, { LANG_KAY } from '@utils/i18n';
import { isWhaleCustomer, isHongCustomer } from '@utils/util';

function lazyFunc(component) {
    const Comp = component;

    return (
        <Suspense fallback={<>{i18n.get(LANG_KAY.COMMON_TITLE_LOADING)}</>}>
            <Comp />
        </Suspense>
    );
}

export default [
    {
        name: 'index',
        path: '/',
        element: lazyFunc(lazy(() => import('../Routers/RouterBeforeEach'))),
        children: [
            {
                name: 'default',
                path: '/',
                element: <Navigate to={isWhaleCustomer() || isHongCustomer() ? '/market' : '/home'} />
            },
            {
                name: 'home',
                path: '/home',
                element: lazyFunc(lazy(() => import('../Home')))
            },
            {
                name: 'doingPage',
                path: '/doingPage',
                element: lazyFunc(lazy(() => import('../DoingPage')))
            }
        ]
    }
];
